import { ActiveSession } from "@/module/auth/model/active-session.model";
import { AuthLoginRoute } from "@/module/auth/page";
import { Store } from "@kinherit/orm/store";

interface LogoutSessionResponse {
  [key: string]: never;
}

export const LogoutSessionHandler =
  async (): Promise<LogoutSessionResponse> => {
    const activeSession = ActiveSession.$getInstance();

    if (null === activeSession) {
      return {};
    }

    Store.purge();
    sessionStorage.clear();
    localStorage.clear();
    ActiveSession.$getInstance()?.$delete();

    // await window.Kernel.ActionBus.execute("dashboard/theme/set", {});

    await window.Kernel.visitRoute({
      name: AuthLoginRoute,
    });

    window.Kernel.ComponentOptions.App.callRefresh();
    window.Kernel.ComponentOptions.Navbar.callRefresh();
    return {};
  };
