import { EnforceMultiFactor } from "@/module/core/action/enfore-multi-factor.action";
import { KinvaultUrlHandler } from "@/module/core/action/kinvault-url.action";
import { CreateMfAuthMethodHandler } from "@/module/core/action/mf-auth-method/create-mf-auth-method.action";
import { DeleteMfAuthMethodHandler } from "@/module/core/action/mf-auth-method/delete-mf-auth-method.action";
import { ReadMfAuthMethodsHandler } from "@/module/core/action/mf-auth-method/read-mf-auth-methods.action";
import { ResendMfAuthMethodHandler } from "@/module/core/action/mf-auth-method/resend-mf-auth-method.action";
import { UpdateMfAuthMethodHandler } from "@/module/core/action/mf-auth-method/update-mf-auth-method.action";
import { ReadEmailAddressHandler } from "@/module/core/action/select/read-email-address.action";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { ReadOptionHandler } from "./read-option.action";
import { SearchAddressHandler } from "./search-address.action";
import { ReadAddressHandler } from "./select/read-address.action";
import { ReadAdviserHandler } from "./select/read-adviser.action";
import { ReadBrandedKinvaultMembershipHandler } from "./select/read-branded-kinvault-membership.action";
import { ReadBrandedKinvaultHandler } from "./select/read-branded-kinvault.action";
import { ReadCheckHandler } from "./select/read-check.action";
import { ReadEmailTemplateHandler } from "./select/read-email-template.action";
import { ReadIntroducerCompanyHandler } from "./select/read-introducer-company.action";
import { ReadIntroducerContactHandler } from "./select/read-introducer-contact.action";
import { ReadIntroducerContractHandler } from "./select/read-introducer-contract.action";
import { ReadKintinAddressHandler } from "./select/read-kintin-address.action";
import { ReadKintinHandler } from "./select/read-kintin.action";
import { ReadKnowledgeBaseCategoryHandler } from "./select/read-knowledge-base-category.action";
import { ReadPersonHandler } from "./select/read-person.action";
import { ReadProductHandler } from "./select/read-product.action";
import { ReadReferralCodeHandler } from "./select/read-referral-code.action";
import { ReadRoleHandler } from "./select/read-role.action";
import { ReadTagHandler } from "./select/read-tag.action";
import { ReadUserHandler } from "./select/read-user.action";

// export const Actions = {
//   // Option
//   "core/option/read": ReadOptionHandler,
//   // Address by postcode auto complete
//   "core/address/search": SearchAddressHandler,
//   // mf-auth-method
//   "core/mf-auth-methods/read": ReadMfAuthMethodsHandler,
//   "core/mf-auth-methods/create": CreateMfAuthMethodHandler,
//   "core/mf-auth-methods/delete": DeleteMfAuthMethodHandler,
//   "core/mf-auth-methods/update": UpdateMfAuthMethodHandler,
//   "core/mf-auth-methods/resend": ResendMfAuthMethodHandler,
//   // Select
//   "core/select/address/kintin/read": ReadKintinAddressHandler,
//   "core/select/address/read": ReadAddressHandler,
//   "core/select/adviser/read": ReadAdviserHandler,
//   "core/select/check/read": ReadCheckHandler,
//   "core/select/introducer-company/read": ReadIntroducerCompanyHandler,
//   "core/select/introducer-contact/read": ReadIntroducerContactHandler,
//   "core/select/introducer-contract/read": ReadIntroducerContractHandler,
//   "core/select/kintin/read": ReadKintinHandler,
//   "core/select/knowledge-base-category/read": ReadKnowledgeBaseCategoryHandler,
//   "core/select/branded-kinvault-membership/read":
//     ReadBrandedKinvaultMembershipHandler,
//   "core/select/branded-kinvault/read": ReadBrandedKinvaultHandler,
//   "core/select/person/read": ReadPersonHandler,
//   "core/select/product/read": ReadProductHandler,
//   "core/select/referral-code/read": ReadReferralCodeHandler,
//   "core/select/role/read": ReadRoleHandler,
//   "core/select/tag/read": ReadTagHandler,
//   "core/select/user/read": ReadUserHandler,
//   "core/select/email-template/read": ReadEmailTemplateHandler,
//   "core/select/email-address/read": ReadEmailAddressHandler,
// };

export const CoreActionBus = DefineActionBus({
  name: "Core",
  actions: {
    options: DefineActionBus({
      name: "Core.Option",
      actions: {
        read: ReadOptionHandler,
      },
    }),
    address: DefineActionBus({
      name: "Core.Address",
      actions: {
        search: SearchAddressHandler,
      },
    }),
    mfAuthMethods: DefineActionBus({
      name: "Core.MfAuthMethods",
      actions: {
        read: ReadMfAuthMethodsHandler,
        create: CreateMfAuthMethodHandler,
        delete: DeleteMfAuthMethodHandler,
        update: UpdateMfAuthMethodHandler,
        resend: ResendMfAuthMethodHandler,
      },
    }),
    select: DefineActionBus({
      name: "Core.Select",
      actions: {
        address: ReadAddressHandler,
        adviser: ReadAdviserHandler,
        check: ReadCheckHandler,
        introducerCompany: ReadIntroducerCompanyHandler,
        introducerContact: ReadIntroducerContactHandler,
        introducerContract: ReadIntroducerContractHandler,
        kintinAddress: ReadKintinAddressHandler,
        kintin: ReadKintinHandler,
        knowledgeBaseCategory: ReadKnowledgeBaseCategoryHandler,
        brandedKinvaultMembership: ReadBrandedKinvaultMembershipHandler,
        brandedKinvault: ReadBrandedKinvaultHandler,
        person: ReadPersonHandler,
        product: ReadProductHandler,
        referralCode: ReadReferralCodeHandler,
        role: ReadRoleHandler,
        tag: ReadTagHandler,
        user: ReadUserHandler,
        emailTemplate: ReadEmailTemplateHandler,
        emailAddress: ReadEmailAddressHandler,
      },
    }),
    KinvaultUrl: KinvaultUrlHandler,
    EnforceMultiFactor,
  },
});
