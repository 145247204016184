<template>
  <PageLayout
    class="partner-portal-account-referral-form"
    title="Refer a client"
    strap="Refer a client to Kinherit for our estate planning service by filling out the form below."
  >
    <Card is-plain>
      <Form
        class="contact-form"
        is-borderless
        :config="$data.form"
        @submit="submit"
      />
      <Button
        color="is-positive"
        class="mt-3 submit"
        size="is-large"
        @click="submit"
      >
        Submit Client
      </Button>
    </Card>
  </PageLayout>
</template>

<script lang="ts">
import { AccountReferralForm } from "@/module/clients/form/create-account-referral.form";
import { PartnerPortalIndexRoute } from "@/module/dashboard/page";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import PageLayout from "@/shared/component/PageLayout.vue";
import Form from "@kinherit/framework/component.form/form";
import Button from "@kinherit/framework/component.input/button";
import { Card } from "@kinherit/framework/component.layout/card";
import { defineComponent } from "vue";
import { PartnerPortalAccountReferralFormRoute } from ".";

export default defineComponent({
  name: PartnerPortalAccountReferralFormRoute,
  components: {
    Card,
    Form,
    Button,
    PageLayout,
  },
  mixins: [
    AuthService.mixin({
      module: "partner-portal",
    }),
  ],
  data: () => ({
    form: AccountReferralForm(),
  }),
  methods: {
    async submit(): Promise<void> {
      if (!this.form.isValid()) {
        this.form.options.showValidation = true;
        return;
      }
      const activeCompany = this.$auth.activeIntroducerCompany;
      const ownedBy = activeCompany?.$data.defaultEstatePlanner ?? null;
      const assignedTo = activeCompany?.$data.defaultLegalAssistant ?? null;

      const payload = {
        ...this.$data.form.localData,
        ownedBy,
        assignedTo,
      };

      const referralCode = payload.referralCode;

      if (!referralCode) {
        return;
      }

      // await window.Kernel.ActionBus.execute(
      //   "partner-portal/clients/create",
      //   payload,
      // );
      await window.Kernel.ActionBus.clients.create({
        ...payload,
        referralCode,
      });

      SnackBarService.success.generic("Client referred successfully");

      this.$router.push({
        name: PartnerPortalIndexRoute,
      });
    },
  },
});
</script>

<cypress-wrapper lang="json">
{
  "name": "AccountReferralFormPageWrapper",
  "route": "PartnerPortalAccountReferralForm",
  "selector": ".partner-portal-account-referral-form",
  "imports": {
    "AccountReferralFormWrapper": "@/module/clients/form/create-account-referral.form.test"
  },
  "methods": {
    "form": {
      "type": "to-one",
      "selector": "",
      "wrapper": "AccountReferralFormWrapper"
    },
    "submit": {
      "type": "click",
      "selector": ".submit"
    }
  }
}
</cypress-wrapper>
