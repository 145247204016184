<template>
  <PageLayout
    class="invite-form"
    title="Invite a Client"
    strap="Invite a client to setup a Kinvault by filling out the form below."
  >
    <Card is-plain>
      <Form
        class="contact-form"
        is-borderless
        :config="$data.form"
        @submit="submit"
      />
      <Button color="is-positive" class="mt-3" size="is-large" @click="submit">
        Create Invitation
        {{
          $data.form.localData.sendInvitationEmail ? "and preview email" : ""
        }}
      </Button>
    </Card>
  </PageLayout>
</template>

<script lang="ts">
import { PartnerPortalIndexRoute } from "@/module/dashboard/page";
import { CreateInviteForm } from "@/module/invites/form/create-invite.form";
import { PreviewInviteForm } from "@/module/invites/form/preview-invite.form";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import PageLayout from "@/shared/component/PageLayout.vue";
import Form from "@kinherit/framework/component.form/form";
import Button from "@kinherit/framework/component.input/button";
import { Card } from "@kinherit/framework/component.layout/card";
import { defineComponent } from "vue";
import { PartnerPortalInviteFormRoute } from ".";

export default defineComponent({
  name: PartnerPortalInviteFormRoute,
  components: {
    Card,
    Form,
    Button,
    PageLayout,
  },
  mixins: [
    AuthService.mixin({
      module: "partner-portal",
    }),
  ],
  data: () => ({
    form: CreateInviteForm(),
  }),
  methods: {
    async submit(): Promise<void> {
      if (!this.form.isValid()) {
        this.form.options.showValidation = true;
        return;
      }

      // Gets LA and EP assignments
      const activeCompany = this.$auth.activeIntroducerCompany;
      const ownedBy = activeCompany?.$data.defaultEstatePlanner ?? null;
      const assignedTo = activeCompany?.$data.defaultLegalAssistant ?? null;

      const sendInvitationEmail = this.$data.form.localData.sendInvitationEmail;
      const payload = {
        ...this.$data.form.localData,
        ownedBy,
        assignedTo,
      };

      const brandedKinvault = this.$data.form.localData.brandedKinvault;
      if (!brandedKinvault) {
        throw new Error("Branded Kinvault is required");
      }
      // console.log(brandedKinvaultId);
      // if (!brandedKinvaultId) {
      //   return;
      // }
      // const brandedKinvault = BrandedKinvault.$findOneByOrThrow({
      //   id: brandedKinvaultId,
      // });
      // console.log(brandedKinvault);

      const brandedKinvaultName =
        brandedKinvault.profile?.organisationName ?? "";

      const adviser = this.$data.form.localData.adviser;
      // const adviser = adviserId
      //   ? IntroducerContact.$findOneByOrThrow({
      //       id: adviserId,
      //     })
      //   : null;

      const adviserName = adviser?.profile?.fullName ?? "";

      const previewInvitationPayload = {
        firstName: this.$data.form.localData.firstName,
        adviserName: adviserName,
        brandedKinvaultName: brandedKinvaultName,
        link: `https://${brandedKinvault.frontendUrl}/EXAMPLE-LINK`,
      };

      if (sendInvitationEmail) {
        // Preview email
        await PreviewInviteForm(previewInvitationPayload).dialog({
          dialog: {
            title: "Preview Invitation",
          },
        });
      }

      const referralCode = payload.referralCode;

      if (!referralCode) {
        throw new Error("Referral code is required");
      }

      // await window.Kernel.ActionBus.execute(
      //   "partner-portal/invites/create",
      //   payload,
      // );
      await window.Kernel.ActionBus.invites.create({
        ...payload,
        referralCode,
        brandedKinvault,
      });

      SnackBarService.success.generic("Invitation created successfully");
      this.$router.push({
        name: PartnerPortalIndexRoute,
      });
    },
  },
});
</script>

<cypress-wrapper lang="json">
{
  "name": "InviteFormPageWrapper",
  "selector": ".invite-form",
  "route": "PartnerPortalInviteForm",
  "imports": {
    "CreateInviteFormWrapper": "@/module/invites/form/create-invite.form.test"
  },
  "methods": {
    "form": {
      "type": "to-one",
      "selector": "form",
      "wrapper": "CreateInviteFormWrapper"
    }
  }
}
</cypress-wrapper>
