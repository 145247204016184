import { FormElement } from "@kinherit/framework/component.form/element";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";

type RequestKinvaultAccessFormData = {
  acceptedTerms: boolean | null;
};

export const RequestKinvaultAccessForm = (alreadyRequested: boolean) =>
  defineForm({
    name: "request-kinvault-access-form",
    data: (): RequestKinvaultAccessFormData => ({
      acceptedTerms: null,
    }),
    formAreas: (data) => [
      defineFormArea({
        name: "request-kinvault-access-form-area",
        data,
        template: GridLayout([["warning"], ["acceptedTerms"]]),
        components: () => ({
          warning: [
            FormElement({
              props: {
                html: `
                <p class='is-size-4'>
                  Kinvault access needs to be granted. This feature will send an
                  email request to the client to permit or deny access. Access
                  requests are are valid for 30 days.
                </p>`,
              },
            }),
          ],
          acceptedTerms: [
            FormCheckboxField({
              props: {
                label:
                  "I understand the client will receive an email request to grant access, and that I am registered with the Financial Conduct Authority.",
                validators: ["required"],
              },
              models: {
                value: "acceptedTerms",
              },
            }),
            FormElement({
              props: {
                vIf: alreadyRequested,
                html: `
                <p class='is-size-4'>
                  Your previous request either was declined or expired. Are you <strong>sure</strong> you want to try again?
                </p>`,
              },
            }),
          ],
        }),
      }),
    ],
  });
