import { RouteInterface } from "@kinherit/framework/core/route-config";

export const HelpIndexRoute = "HelpFaqIn";
export const HelpFaqEstatePlanningRoute = "HelpFaqEstatePlanning";
export const HelpFaqKinvaultRoute = "HelpFaqKinvault";
export const HelpFaqKinvaultAccessRoute = "HelpFaqKinvaultAccess";

import {
  HelpFaqEstatePlanningBreadCrumb,
  HelpFaqKinvaultBreadCrumb,
  HelpFaqKinvaultAccessBreadCrumb,
  HelpIndexBreadCrumb,
} from "../breadcrumbs";

export const Routes: Array<RouteInterface> = [
  {
    name: HelpIndexRoute,
    path: "/help",
    component: () => import("./Index.vue"),
    redirect: { name: HelpFaqEstatePlanningRoute },
    meta: {
      breadcrumbs: () => [],
    },
    children: [
      {
        name: HelpFaqEstatePlanningRoute,
        path: "estate-planning",
        component: () => import("./FaqEstatePlanning.vue"),
        meta: {
          breadcrumbs: () => [
            HelpIndexBreadCrumb,
            HelpFaqEstatePlanningBreadCrumb,
          ],
        },
      },
      {
        name: HelpFaqKinvaultRoute,
        path: "kinvault",
        component: () => import("./FaqKinvault.vue"),
        meta: {
          breadcrumbs: () => [HelpIndexBreadCrumb, HelpFaqKinvaultBreadCrumb],
        },
      },
      {
        name: HelpFaqKinvaultAccessRoute,
        path: "kinvault-access",
        component: () => import("./FaqKinvaultAccess.vue"),
        meta: {
          breadcrumbs: () => [
            HelpIndexBreadCrumb,
            HelpFaqKinvaultAccessBreadCrumb,
          ],
        },
      },
    ],
  },
];
