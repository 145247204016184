<template>
  <div class="partner-portal">
    <Message
      v-if="showMfaWarning"
      color="is-warning"
      class="mb-5 is-clickable"
      @click="viewMyProfile"
    >
      <strong
        >Required: setup Multifactor authentication to protect your
        account</strong
      >
      <span class="is-block"
        >All Admin &amp; Staff accounts must have 2FA enabled.</span
      >
    </Message>
    <PageLayout
      v-if="isDashboard"
      :title="'Welcome ' + $auth.activeIntroducerContact?.profile?.firstName"
      strap="Your online place to make new referrals, resolve issues and keep up-to-date with client progress."
    >
      <template v-if="computedSections.length > 0">
        <p>
          Any questions? Email
          <a href="mailto:sales@kinherit.co.uk">sales@kinherit.co.uk</a> or
          check our
          <span class="has-text-link is-clickable" @click="handleFaqClick"
            >FAQ</span
          >.
        </p>
        <DashboardSection
          v-for="(section, i) in computedSections"
          :key="`partner-section-${i}`"
          :title="section.title"
          :items="section.items"
        />
      </template>
      <Message
        v-if="computedSections.length === 0"
        title="Permissions Error"
        color="is-danger"
        text="Sorry, but you do not have access to any functionality in the Partner Portal. Please contact us if you believe this to be incorrect."
      >
        <p>
          You do not have access to any sections in the Partner Portal. Please
          contact us.
        </p>
      </Message>
    </PageLayout>
    <RouterChildView v-if="!isDashboard" />
  </div>
</template>

<script lang="ts">
import { SettingsCreateMfAuthMethodsRoute } from "@/module/core/page/settings";
import { HelpFaqEstatePlanningRoute } from "@/module/help/page";
import {
  PartnerPortalInviteFormRoute,
  PartnerPortalInviteMasterListRoute,
} from "@/module/invites/page";
import { AuthService } from "@/service/auth.service";
import DashboardSection from "@/shared/component/DashboardSection.vue";
import PageLayout from "@/shared/component/PageLayout.vue";
import Message from "@kinherit/framework/component.display/message";
import RouterChildView from "@kinherit/framework/component.misc/router-child-view";
import { ThemeIconName } from "@kinherit/framework/theme/prop/icon";
import { defineComponent } from "vue";
import { PartnerPortalIndexRoute } from ".";
import {
  PartnerPortalAccountReferralFormRoute,
  PartnerPortalAccountReferralMasterListRoute,
} from "./../../clients/page";

export default defineComponent({
  name: PartnerPortalIndexRoute,
  components: {
    PageLayout,
    RouterChildView,
    DashboardSection,
    Message,
  },
  mixins: [
    AuthService.mixin({
      module: "partner-portal",
    }),
  ],
  data: () => ({
    showMfaWarning: false,
    PartnerPortalAccountReferralMasterListRoute,
    allSections: [
      {
        title: "Clients",
        items: [
          {
            title: "Your Clients",
            strap: "View client progress and help us resolve issues",
            route: PartnerPortalAccountReferralMasterListRoute,
            icon: ThemeIconName.People,
            permission: "clientsRead" as const,
            permissionValue: ["any", "own"],
            class: "your-clients",
          },
          {
            title: "Refer a Client",
            strap: "It’s quick and easy with our online form",
            route: PartnerPortalAccountReferralFormRoute,
            icon: ThemeIconName.Plus,
            permission: "clientsCreate" as const,
            permissionValue: true,
            class: "refer-a-client",
          },
        ],
      },
      {
        title: "Kinvault",
        items: [
          {
            title: "Your Invitations",
            strap: "View and manage your client invites",
            route: PartnerPortalInviteMasterListRoute,
            icon: ThemeIconName.People,
            permission: "enableStandaloneKinvault" as const,
            permissionValue: true,
            class: "your-invitations",
          },
          {
            title: "Invite a Client",
            strap: "Send a client a Kinvault invitation",
            route: PartnerPortalInviteFormRoute,
            icon: ThemeIconName.Email,
            permission: "invitationsCreate" as const,
            permissionValue: true,
            class: "invite-a-client",
          },
        ],
      },
    ],
  }),
  computed: {
    isDashboard() {
      return this.$route.name === PartnerPortalIndexRoute;
    },
    /*
      Dependent on permissions, we should only display whatever sections they can access
    */
    computedSections() {
      const permissions: typeof AuthService.portalPermissions =
        this.$auth.portalPermissions;
      const sections = [];
      // Loop over each main section: only return items where the permission matches
      for (const section of this.allSections) {
        const items = section.items.filter((item) => {
          if (item.permission) {
            if (item.permissionValue === true) {
              return permissions[item.permission] === true;
            } else if (Array.isArray(item.permissionValue)) {
              return item.permissionValue.includes(
                permissions[item.permission] as string,
              );
            }
          }
          return true;
        });
        if (items.length > 0) {
          sections.push({
            title: section.title,
            items,
          });
        }
      }
      return sections;
    },
  },
  async mounted() {
    // const { hasMultiFactor, enforce } =
    //   await window.Kernel.ActionBus.core.EnforceMultiFactor();
    // if (!hasMultiFactor && enforce) {
    //   this.showMfaWarning = true;
    // }
  },
  methods: {
    handleFaqClick() {
      window.Kernel.visitRoute({
        name: HelpFaqEstatePlanningRoute,
      });
    },
    viewMyProfile() {
      window.Kernel.visitRoute({
        name: SettingsCreateMfAuthMethodsRoute,
      });
    },
  },
});
</script>

<cypress-wrapper lang="json">
{
  "name": "DashboardPageWapper",
  "selector": ".partner-portal",
  "route": "PartnerPortalIndex",
  "methods": {
    "yourClients": {
      "type": "click",
      "selector": ".your-clients"
    },
    "referAClient": {
      "type": "click",
      "selector": ".refer-a-client"
    },
    "yourInvitations": {
      "type": "click",
      "selector": ".your-invitations"
    },
    "inviteAClient": {
      "type": "click",
      "selector": ".invite-a-client"
    }
  }
}
</cypress-wrapper>
