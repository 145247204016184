<template>
  <div class="page-layout">
    <slot name="header">
      <h1 v-if="title" class="is-display">
        {{ title }}
      </h1>
      <p v-if="strap" class="lead">
        {{ strap }}
      </p>
    </slot>
    <slot />
    <slot name="footer" />
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "PageLayout",
  props: {
    title: {
      type: String as PropType<string | null>,
      default: null,
    },
    strap: {
      type: String as PropType<string | null>,
      default: null,
    },
  },
});
</script>

<style lang="scss" scoped>
.page-layout {
  padding-left: var(--card-padding-left);
  padding-right: var(--card-padding-right);
  padding-top: var(--card-padding-top);
  padding-bottom: var(--card-padding-bottom);
}
</style>
