import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { BrandedKinvaultInvitation } from "@kinherit/sdk";

// export const DeleteInviteHandler = async (message: {
//   brandedKinvaultInvitation: BrandedKinvaultInvitation;
// }) => {
//   await Api.resource(
//     "partner-portal",
//     "/v2/partner-portal/branded-kinvault-invitation/{brandedKinvaultInvitation}",
//     message,
//   )
//     .method("delete")
//     .result();
// };

export const DeleteInviteHandler = DefineDeleteAction({
  interface: "partner-portal",
  resource:
    "/v2/partner-portal/branded-kinvault-invitation/{brandedKinvaultInvitation}",
  method: "delete",
  parse: (message: {
    brandedKinvaultInvitation: BrandedKinvaultInvitation;
  }) => ({
    params: message,
  }),
});
