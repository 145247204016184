// import {
//   AccountReferral,
//   AccountReferralCode,
//   Api,
//   EmailAddress,
//   IntroducerContact,
//   Lead,
//   Note,
//   Option,
//   PhoneNumber,
//   Profile,
// } from "@kinherit/sdk";
// import { DateTime } from "@kinherit/ts-common";

// export const CreateAccountReferralHandler = async (message: {
//   firstName: string;
//   lastName: string;
//   tel: string;
//   email: string;
//   message: string;
//   contactWindow: "asap" | "fromDate" | "noContact";
//   contactDate: null | DateTime;
//   possibleProducts: Array<string>;
//   referralContact: IntroducerContact | null;
//   referralCode: AccountReferralCode;
//   ownedBy?: string | null;
//   assignedTo?: string | null;
// }) => {
//   // If account referral is being created after 2pm then set the next action to the next day
//   const now = new DateTime();

//   const nextActionAt =
//     now.getTime().toHours() > 14 ? now.clone().add(1, "day") : now;

//   const newLeadStatus = Option.$findOneByOrThrow({
//     group: "leadStatus",
//     value: "new",
//   }).id;
//   const request = Api.resource(
//     "partner-portal",
//     "/v2/partner-portal/lead",
//     message,
//   )
//     .method("post")
//     .body({
//       profile: {
//         firstName: message.firstName,
//         lastName: message.lastName,
//         phoneNumbers: [{ tel: message.tel, primary: true }],
//         emails: [{ email: message.email, primary: true }],
//       },
//       notes: [
//         {
//           name: `Referral created`,
//           notes: [
//             `Contact window: ${
//               {
//                 asap: "As soon as possible",
//                 fromDate: `From ${message.contactDate?.formatDate}`,
//                 noContact: "No contact",
//               }[message.contactWindow]
//             }`,
//             `Possible products: ${message.possibleProducts.join(", ")}`,
//             `Message: ${message.message}`,
//           ].join(`\n`),
//           pinned: false,
//           type: "note",
//         },
//       ],
//       referral: {
//         // If only one referral code, or selected one
//         referralCode: message.referralCode.id,
//         // Either the current introducer contact or the selected one
//         contact: message.referralContact?.id,
//         nextActionAt: nextActionAt.formatMachine,
//       },
//       ownedBy: message.ownedBy,
//       assignedTo: message.assignedTo,
//       description: `Via Partner Portal`,
//       status: newLeadStatus,
//     });

//   const response = await request.result();

//   return {
//     lead: Lead.$inflate(response.lead),
//     profile: Profile.$inflate(response.profile),
//     emailAddress: EmailAddress.$inflate(response.emailAddress),
//     phoneNumber: PhoneNumber.$inflate(response.phoneNumber),
//     note: Note.$inflate(response.note),
//     accountReferral: AccountReferral.$inflate(response.accountReferral),
//   };
// };

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import {
  AccountReferralCode,
  Api,
  IntroducerContact,
  Option,
} from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common";

export const CreateAccountReferralHandler = DefineWriteAction({
  interface: "partner-portal",
  resource: "/v2/partner-portal/lead",
  method: "post",
  parse: (message: {
    primary: {
      firstName: string;
      lastName: string;
      tel: string;
      email: string;
    };
    secondary: {
      firstName: string;
      lastName: string;
      tel: string;
      email: string;
    };
    isJoint: boolean;
    message: string;
    contactWindow: "asap" | "fromDate" | "noContact";
    contactDate: null | DateTime;
    possibleProducts: Array<string>;
    referralContact: IntroducerContact | null;
    referralCode: AccountReferralCode;
    ownedBy?: string | null;
    assignedTo?: string | null;
    files: Array<File>;
  }) => {
    const newLeadStatus = Option.$findOneByOrThrow({
      group: "leadStatus",
      value: "new",
    }).id;

    // If account referral is being created after 2pm then set the next action to the next day
    // Except after 2pm on Friday, then set the next action to Monday
    const now = new DateTime();
    const isAfter2pm = now.getTime().toHours() > 14;
    const isFriday = now.is("friday");
    const isWeekend = now.is("saturday") || now.is("sunday");

    const daysToAdd = isFriday
      ? isAfter2pm
        ? 3
        : 1
      : isWeekend
        ? now.is("saturday")
          ? 2
          : 1
        : 1;
    const nextActionAt =
      isAfter2pm || isWeekend ? now.clone().add(daysToAdd, "day") : now;

    return {
      body: {
        primaryProfile: {
          firstName: message.primary.firstName,
          lastName: message.primary.lastName,
          phoneNumbers: [{ tel: message.primary.tel, primary: true }],
          emails: [{ email: message.primary.email, primary: true }],
        },
        secondaryProfile: message.isJoint
          ? {
              firstName: message.secondary.firstName,
              lastName: message.secondary.lastName,
              phoneNumbers: message.secondary.tel
                ? [{ tel: message.secondary.tel, primary: true }]
                : [],
              emails: message.secondary.email
                ? [{ email: message.secondary.email, primary: true }]
                : [],
            }
          : null,
        notes: [
          {
            name: `Referral created`,
            notes: [
              `Contact window: ${
                {
                  asap: "As soon as possible",
                  fromDate: `From ${message.contactDate}`,
                  noContact: "No contact",
                }[message.contactWindow]
              }`,
              `Possible products: ${message.possibleProducts.join(", ")}`,
              `Message: ${message.message}`,
            ].join(`\n`),
            pinned: false,
            type: "note" as const,
          },
        ],
        referral: {
          referralCode: message.referralCode.id,
          contact: message.referralContact?.id,
          nextActionAt: nextActionAt.formatMachine,
        },
        ownedBy: message.ownedBy,
        assignedTo: message.assignedTo,
        description: `Via Partner Portal`,
        status: newLeadStatus,
      },
    };
  },
  after: async ({ message, response }) => {
    const { id: noteId } = response.note.first("Failed to find lead note");

    for (let i = 0; i < message.files.length; i++) {
      const file = message.files[i];
      await Api.resource(
        "partner-portal",
        "/v2/partner-portal/lead/{note}/upload",
        { note: noteId },
      )
        .method("put")
        .files({
          uploadedFile: file,
        })
        .result();
    }
  },
});
