import { Model } from "@kinherit/orm";

export interface SessionData {
  id: string;
  token: string;
  mfaRequired: boolean;
  mfaHasMethods: boolean;
  mfaSetupRequired: boolean;
  methods: Array<{
    type: "email" | "sms" | "totp";
    id: string;
    alias: string | null;
    device: string | null;
  }>;
  user: string;
  accountType: "IntroducerContact";
}

export class Session extends Model<SessionData> {
  public readonly $relations = () => ({});

  public get username(): string {
    return this.$data.id;
  }

  public get token(): string {
    return this.$data.token;
  }

  public get mfaRequired(): boolean {
    return this.$data.mfaRequired;
  }

  public get mfMethods(): Array<{
    type: "email" | "sms" | "totp";
    id: string;
    alias: string | null;
    device: string | null;
  }> {
    return this.$data.methods;
  }

  public get user(): string {
    return this.$data.user;
  }

  public get accountType(): "IntroducerContact" {
    return this.$data.accountType;
  }

  public get mfaHasMethods(): boolean {
    return this.$data.mfaHasMethods;
  }

  public get mfaSetupRequired(): boolean {
    return this.$data.mfaSetupRequired;
  }
}
