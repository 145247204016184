<template>
  <div class="columns is-centered reset-password-page">
    <div class="column is-6-tablet is-4-desktop">
      <Card :title="title">
        <div v-if="hasToken && hasValidToken === true">
          <Form is-borderless :config="$data.form" @submit="handleClick" />
          <Button
            class="reset-password-button mt-3"
            :is-fullwidth="true"
            color="is-primary"
            @click="handleClick"
          >
            Set my Password
          </Button>
          <Button
            :is-fullwidth="true"
            class="mt-3"
            color="is-light-grey"
            @click="navigateToLogin"
          >
            Cancel and return to Login
          </Button>
        </div>

        <div v-if="hasToken">
          <div v-if="hasValidToken === null">
            <p>Checking validity...</p>
          </div>
          <div v-if="hasValidToken === false">
            <p>
              The password reset link you followed was either invalid or has
              already expired.
            </p>
            <Button
              :is-fullwidth="true"
              class="mt-3"
              color="is-light-grey"
              @click="navigateToLogin"
            >
              Cancel and return to Login
            </Button>
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script lang="ts">
import { SnackBarService } from "@/service/snack-bar.service";
import Form from "@kinherit/framework/component.form/form";
import Button from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { defineComponent } from "vue";
import { AuthLoginRoute, AuthPasswordResetRoute } from ".";
import { ResetPasswordForm } from "../form/reset-password.form";
export default defineComponent({
  name: AuthPasswordResetRoute,
  components: {
    Card,
    Form,
    Button,
  },
  mixins: [],
  data: () => ({
    form: ResetPasswordForm(),
    hasToken: false,
    hasValidToken: null as boolean | null,
  }),
  computed: {
    title() {
      return this.hasToken && this.hasValidToken === true
        ? "Set Your Password"
        : "Error";
    },
    $params() {
      return this.$route.params;
    },
  },
  async mounted(): Promise<void> {
    this.hasToken = this.$params.token !== undefined;
    try {
      // await window.Kernel.ActionBus.execute("auth/validate-token", {
      //   token: this.$params.token as string,
      // });
      await window.Kernel.ActionBus.auth.changePassword.validateToken({
        token: this.$params.token as string,
      });
      this.hasValidToken = true;
    } catch {
      this.hasValidToken = false;
    }
  },
  methods: {
    async handleClick() {
      if (!this.form.isValid()) {
        this.form.options.showValidation = true;
        return;
      }

      const data = this.form.localData;
      try {
        // await window.Kernel.ActionBus.execute("auth/set-password", {
        //   token: this.$params.token as string,
        //   newPassword: data.password,
        // });
        await window.Kernel.ActionBus.auth.changePassword.setPassword({
          token: this.$params.token as string,
          newPassword: data.password,
        });
      } catch {
        //
      }
      SnackBarService.toast({
        text: "Password reset successfully",
        type: "success",
      });

      this.navigateToLogin();
    },
    navigateToLogin() {
      window.Kernel.visitRoute({
        name: AuthLoginRoute,
      });
    },
  },
});
</script>

<cypress-wrapper lang="json">
{
  "name": "PasswordResetPageWrapper",
  "imports": {
    "ResetPasswordFormWrapper": "@/module/auth/form/reset-password.form.test"
  },
  "route": "AuthPasswordReset",
  "selector": ".reset-password-page",
  "methods": {
    "form": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ResetPasswordFormWrapper"
    },
    "resetPassword": {
      "type": "click",
      "selector": ".reset-password-button"
    },
    "returnToLogin": {
      "type": "click",
      "selector": ".return-to-login-button"
    }
  }
}
</cypress-wrapper>
