var s = Object.defineProperty;
var c = (e, r) => s(e, "name", { value: r, configurable: !0 });
const P = /* @__PURE__ */ c((e) => (r) => new e(r).execute(), "ActionToMethod"), b = /* @__PURE__ */ c(({
  $pagination: e
}) => ({
  currentPage: Number.parseInt((e == null ? void 0 : e.currentPage) ?? "1"),
  lastPage: Number.parseInt((e == null ? void 0 : e.lastPage) ?? "0"),
  count: Number.parseInt((e == null ? void 0 : e.count) ?? "0")
}), "getPagination");
export {
  P as ActionToMethod,
  b as getPagination
};
