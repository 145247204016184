<template>
  <div class="help-faq-kinvault">
    <Card title="Inviting clients">
      <FaqAccordian question="What is a Kinvault invitation?">
        A Kinvault invitation allows you to invite your clients to have a
        Kinvault without having to be a Kinherit estate planning client. This is
        ideal for those who have an existing Will, but who you would like to
        offer the benefits of a Kinvault to.
      </FaqAccordian>
      <FaqAccordian question="How does it work?">
        Once enabled, you will be able to add your clients to your branded
        Kinvault. You can either select for us to send the invitation on your
        behalf, or you can contact the client yourself, using the provided
        sign-up link. Once your client has accepted the invitation using the
        link, they will be able to create their account and access their
        Kinvault.
      </FaqAccordian>
      <FaqAccordian question="How do I invite a client?">
        Simply fill out the
        <span
          class="is-clickable has-text-weight-bold has-text-positive"
          title="Go to Form"
          @click="handleInviteFormClick"
          >form here</span
        >
        (assuming you have the correct permissions). You will need the first
        name, last name and email address of the primary account holder – for
        couples, you should only invite one person: they’ll have the option to
        create a shared Kinvault when they accept the invitation.
      </FaqAccordian>
      <FaqAccordian question="Can I bulk upload invitations?">
        Please contact
        <a href="mailto:support@kinvault.com">support@kinvault.com</a> and we
        can arrange a bulk import via CSV.
      </FaqAccordian>
      <FaqAccordian
        question="I get an error when trying to invite my client – why?"
      >
        Invitations are unique per email address; if might be there’s an
        existing invitation, or that that email address already has an account
        on our system. If you would like us to investigate further, please
        contact
        <a href="mailto:support@kinvault.com">support@kinvault.com</a>
      </FaqAccordian>
    </Card>
    <Card title="Managing invitations">
      <FaqAccordian question="An invitation is listed as “Not Sent”">
        If an invitation is listed as “Not Sent” this means Kinvault have not
        sent an invitation to the client – usually this is because the option
        will have been unchecked when adding an invitation. The expectation is
        that you will copy the invitation link and send it to the client
        directly. Once you’ve done this, you can then mark the invitation as
        sent for your records.
      </FaqAccordian>
      <FaqAccordian question="An invitation is listed as “Sent”">
        If an invitation is listed as “Sent” this means that either Kinvault has
        sent the invitation directly to the client, or it has been marked as
        sent by an adviser. The client has not yet created an account or
        declined the invitation.
      </FaqAccordian>
      <FaqAccordian question="An invitation is listed as “Complete”">
        If an invitation is listed as “Complete” this means the client has
        successfully created an account as part of the invitation process, and
        there is no further action required. You will be able to see the
        completed client in the main client listings at this point.
      </FaqAccordian>
      <FaqAccordian question="An invitation is listed as “Failed”">
        If an invitation is listed as “Failed” this means either the invitation
        could not be sent, or something else has failed in the process. Please
        contact
        <a href="mailto:support@kinvault.com">support@kinvault.com</a> if you
        have further questions.
      </FaqAccordian>
      <FaqAccordian question="An invitation is listed as “Declined”">
        If an invitation is listed as “Declined” this means the client has
        actively declined this invitation – this can be as a result of the
        client declining during the account creation process, or if the
        invitation has been manually marked as declined by an adviser.
      </FaqAccordian>
      <FaqAccordian question="I want to delete an invitation">
        You can delete an invitation at any time, but bear in mind if the client
        has already received an invitation link, that link will cease to work.
        An invitation can only be deleted if that client has not gone on to
        create an account.
      </FaqAccordian>
    </Card>
    <Card title="Billing">
      <FaqAccordian
        question="What happens if the client goes on to create a Will with Kinherit?"
      >
        As all our estate planning clients get a Kinvault as part of our main
        offering, there will be no further payment required for that client to
        have a Kinvault, assuming they proceed. Their account will be converted,
        and you will see that client’s progress in the main client section.
      </FaqAccordian>
      <FaqAccordian question="When does my organisation get billed?">
        We bill for Kinvault usage at the beginning of each quarter for new
        account activations and renewals for the prior quarter. New user signups
        and annual renewals are separated out in the invoice.
      </FaqAccordian>
      <FaqAccordian
        question="How can I update my organisation’s billing information?"
      >
        For all billing enquiries, please contact
        <a href="mailto:sales@kinherit.co.uk">sales@kinherit.co.uk</a>
      </FaqAccordian>
    </Card>
  </div>
</template>

<script lang="ts">
import { PartnerPortalInviteFormRoute } from "@/module/invites/page";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import Card from "@kinherit/framework/component.layout/card";
import { defineComponent } from "vue";
import { HelpFaqKinvaultRoute } from ".";
import FaqAccordian from "../component/FaqAccordian.vue";
export default defineComponent({
  name: HelpFaqKinvaultRoute,
  components: { Card, FaqAccordian },
  mixins: [
    AuthService.mixin({
      module: "partner-portal",
    }),
  ],
  methods: {
    handleInviteFormClick() {
      const permissions: typeof AuthService.portalPermissions =
        this.$auth.portalPermissions;
      if (!permissions.invitationsCreate) {
        SnackBarService.toast({
          text: "You do not have permission to create invitations. Please contact support.",
          type: "error",
        });
        return;
      }
      window.Kernel.visitRoute({
        name: PartnerPortalInviteFormRoute,
      });
    },
  },
});
</script>
