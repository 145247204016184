import { AuthService } from "@/service/auth.service";
import { Api, Kintin, KinvaultAuthToken } from "@kinherit/sdk";

export const CreateKinvaultAuthTokenHandler = async (message: {
  kintin: Kintin;
}) => {
  const contact = AuthService.activeIntroducerContact;

  if (!contact) {
    throw new Error("No active introducer contact found");
  }

  const result = await Api.resource(
    "partner-portal",
    "/v2/partner-portal/kinvault-auth-token",
    message,
  )
    .method("post")
    .body({
      contact: contact.id,
      kintin: message.kintin.id,
    })
    .result();

  const token = KinvaultAuthToken.$inflate(result.kinvaultAuthToken).first();

  if (!token) {
    throw new Error("Failed to create kinvault auth token");
  }

  return token;
};
