<template>
  <PageLayout
    v-if="isDashboard"
    title="My Account"
    strap="Update your password or setup Multifactor Authentication"
  >
    <DashboardSection title="Security" :items="tiles" />
  </PageLayout>
  <RouterChildView v-if="!isDashboard" />
</template>

<script lang="ts">
import { AuthService } from "@/service/auth.service";
import DashboardSection from "@/shared/component/DashboardSection.vue";
import PageLayout from "@/shared/component/PageLayout.vue";
import { TDashboardTile } from "@/shared/component/dashboard-tile";
import RouterChildView from "@kinherit/framework/component.misc/router-child-view";
import { ThemeIconName } from "@kinherit/framework/theme/prop/icon";
import { defineComponent } from "vue";
import {
  SettingsChangePasswordRoute,
  SettingsIndexRoute,
  SettingsMfAuthMethodsRoute,
} from ".";

export default defineComponent({
  name: SettingsIndexRoute,
  components: {
    RouterChildView,
    DashboardSection,
    PageLayout,
  },
  mixins: [
    AuthService.mixin({
      module: "logged-in",
    }),
  ],
  computed: {
    tiles(): TDashboardTile[] {
      return [
        {
          title: "Change Password",
          //color: ThemeColor.isPlain,
          strap: "Set a new password for your account",
          icon: ThemeIconName.People,
          route: SettingsChangePasswordRoute,
          //onClick: this.changePassword,
          class: "change-password",
        },
        {
          title: "Authentication",
          //color: ThemeColor.isPlain,
          strap: "Add multifactor authentication to keep your account secure",
          route: SettingsMfAuthMethodsRoute,
          icon: ThemeIconName.Lock,
          //onClick: this.authenticator,
          class: "authentication",
        },
      ];
    },
    isDashboard() {
      return this.$route.name === SettingsIndexRoute;
    },
  },
});
</script>
