<template>
  <div class="faq-accordian">
    <div class="accordion mb-2">
      <div class="accordion-header p-1" @click="isOpen = !isOpen">
        {{ question }}
        <Icon v-if="isOpen" :icon="ThemeIconName.ChevronUp" />
        <Icon v-else :icon="ThemeIconName.ChevronDown" />
      </div>
      <div v-if="isOpen">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="accordion-body">
          <slot />
        </div>
        <div v-if="link" class="accordion-footer">
          <a
            :href="link"
            title="View more information"
            class="strong"
            target="_blank"
          >
            More information
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Icon } from "@kinherit/framework/component.display/icon";
import { ThemeIconName } from "@kinherit/framework/theme/prop/icon";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "FaqAccordian",
  components: { Icon },
  props: {
    question: {
      type: String,
      required: true,
    },
    link: {
      type: String as PropType<string | null>,
      default: null,
    },
  },
  data: () => ({
    ThemeIconName,
    isOpen: false,
  }),
});
</script>

<style scoped>
.accordion-header {
  font-weight: bold;
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
}
.accordion-header:hover {
  background-color: #f8f8f8;
}
.accordion-body {
  padding: 1rem;
}
</style>
