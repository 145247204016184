import { AuthLoginRoute, AuthRequestMfRoute } from "@/module/auth/page";
import { LoginDialogService } from "@/service/login-dialog.service";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { Store } from "@kinherit/orm";
import { Api } from "@kinherit/sdk";
import { ActiveSession } from "../module/auth/model/active-session.model";
Api.setup({
  baseURL: import.meta.env.VITE_APP_API_URL,
});

Api.callback(async (request, axiosInstance) => {
  const headers: Record<string, string> = {};

  const activeSession = ActiveSession.$getInstance();

  if (null !== activeSession) {
    headers["Authorization"] = `Bearer ${activeSession.token}`;
  }

  const response = await axiosInstance.request({
    ...request,
    headers: {
      ...headers,
      ...(request.headers ?? {}),
    },
  });

  return response.data;
});

const LogoutHandler = async () => {
  Store.purge();
  window.Kernel.visitRoute({
    name: AuthLoginRoute,
  });

  return false;
};

Api.catch("TokenMissingException", LogoutHandler);

Api.catch("UserNotFoundException", LogoutHandler);

Api.catch("IncorrectTokenLevelException", async () => {
  throw new Error("Method not implemented.");
});

Api.catch("TokenIncompleteException", async () => {
  window.Kernel.visitRoute({
    name: AuthRequestMfRoute,
  });
  return true;
});

Api.catch("TokenExpiredException", async () => {
  if (window.Kernel.Mode === KernelModes.Test) {
    // window.Kernel.ActionBus.execute("auth/session/logout", {});
    await window.Kernel.ActionBus.auth.session.logout();
    return false;
  }

  try {
    await QueueAuthRequest();
  } catch {
    // window.Kernel.ActionBus.execute("auth/session/logout", {});
    await window.Kernel.ActionBus.auth.session.logout();

    return false;
  }
  return true;
});

export { Api };

const LoginQueue: Array<{
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  resolve: Function;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  reject: Function;
}> = [];

const QueueAuthRequest = async () => {
  const promise = new Promise((resolve, reject) => {
    LoginQueue.push({
      resolve,
      reject,
    });

    if (LoginQueue.length === 1) {
      new LoginDialogService()
        .login()
        .then(() => {
          LoginQueue.forEach((item) => {
            item.resolve();
          });
        })
        .catch((e) => {
          LoginQueue.forEach((item) => {
            item.reject(e);
          });
        })
        .finally(() => {
          LoginQueue.splice(0, LoginQueue.length);
        });
    }
  });
  const result = await await promise;

  return result;
};
