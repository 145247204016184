<template>
  <CreateMfAuthMethod
    v-if="$auth.activeIntroducerContact"
    :introducer-contact="$auth.activeIntroducerContact"
    :show-cancel="false"
    :show-back="true"
    @back="done"
  />
</template>

<script lang="ts">
import { ActiveSession } from "@/module/auth/model/active-session.model";
import { AuthCreateMfRoute } from "@/module/auth/page";
import { PartnerPortalIndexRoute } from "@/module/dashboard/page";
import { AuthService } from "@/service/auth.service";
import CreateMfAuthMethod from "@/shared/component/CreateMfAuthMethod.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: AuthCreateMfRoute,
  components: { CreateMfAuthMethod },
  mixins: [
    AuthService.mixin({
      module: "logged-in",
    }),
  ],
  methods: {
    done(): void {
      const activeSession = ActiveSession.$getInstance();

      if (!activeSession) {
        return;
      }

      ActiveSession.$create({
        ...activeSession.$data,
        mfaSetupRequired: false,
      }).$persist();

      this.$router.push({
        name: PartnerPortalIndexRoute,
      });
    },
  },
});
</script>
