import { Api } from "@kinherit/sdk/api";
import { BrandedKinvaultInvitation } from "@kinherit/sdk";

export const SendInviteEmailHandler = async (message: {
  brandedKinvaultInvitation: string | BrandedKinvaultInvitation;
}) => {
  await Api.resource(
    "partner-portal",
    "/v2/partner-portal/branded-kinvault-invitation/{brandedKinvaultInvitation}/send-invitation-email",
    message,
  )
    .method("put")
    .result();
};
