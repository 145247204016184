<template>
  <PageLayout title="Change Password" strap="Update your password">
    <div v-if="!changed" is-plain>
      <p class="is-size-4">
        You are strongly encouraged to make sure this password is unique and
        only used to access the Kinherit Partner Portal.
      </p>
      <Form is-borderless :config="$data.form" @submit="submitHandler" />
      <div class="buttons is-right">
        <Button text="Cancel" class="cancel-button" @click="cancelHandler" />
        <Button
          text="Change Password"
          class="change-password-button"
          :is-disabled="!$data.form.isValid()"
          @click="submitHandler"
          @click:disabled="form.options.showValidation = true"
        />
      </div>
    </div>
    <div v-else title="Password Changed" is-plain>
      <p>Your password has been changed.</p>
      <div class="buttons is-right">
        <Button text="Back" class="back-button" @click="cancelHandler" />
      </div>
    </div>
  </PageLayout>
</template>

<script lang="ts">
import PageLayout from "@/shared/component/PageLayout.vue";
import Form from "@kinherit/framework/component.form/form";
import Button from "@kinherit/framework/component.input/button";
import { defineComponent } from "vue";
import { SettingsChangePasswordRoute } from ".";
import { ChangePasswordForm } from "../../form/change-password.form";

export default defineComponent({
  name: SettingsChangePasswordRoute,
  components: { Button, Form, PageLayout },
  data: () => ({
    form: ChangePasswordForm(),
    changed: false,
  }),
  methods: {
    cancelHandler(): void {
      this.$router.back();
    },
    async submitHandler(): Promise<void> {
      // await window.Kernel.ActionBus.execute(
      //   "partner-portal/settings/change-password",
      //   {
      //     newPassword: this.form.localData.newPassword,
      //     oldPassword: this.form.localData.oldPassword,
      //   },
      // );
      await window.Kernel.ActionBus.dashboard.settings.changePassword({
        newPassword: this.form.localData.newPassword,
        oldPassword: this.form.localData.oldPassword,
      });

      this.changed = true;
    },
  },
});
</script>

<cypress-wrapper lang="json">
{
  "name": "SettingsChangePassword",
  "imports": {
    "PasswordFieldWrapper": "@kinherit/framework/component.input/password-field/password-field.test"
  },
  "methods": {
    "oldPassword": {
      "type": "to-one",
      "selector": ".old-password",
      "wrapper": "PasswordFieldWrapper"
    },
    "newPassword": {
      "type": "to-one",
      "selector": ".new-password",
      "wrapper": "PasswordFieldWrapper"
    },
    "back": {
      "type": "click",
      "selector": ".back-button"
    },
    "changePassword": {
      "type": "click",
      "selector": ".change-password-button"
    },
    "cancel": {
      "type": "click",
      "selector": ".cancel-button"
    }
  }
}
</cypress-wrapper>
