import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import {
  AccountReferralCode,
  BrandedKinvault,
  IntroducerContact,
} from "@kinherit/sdk";

// export const CreateInviteHandler = async (message: {
//   firstName: string;
//   lastName: string;
//   email: string;
//   externalRef: null | string;
//   referralCode: null | string;
//   adviser: null | string;
//   sendInvitationEmail: null | boolean;
//   brandedKinvault: null | string;
// }) => {
//   if (null === message.sendInvitationEmail) {
//     throw new Error("sendInvitationEmail is required");
//   }

//   if (null === message.brandedKinvault) {
//     throw new Error("brandedKinvault is required");
//   }

//   await Api.resource(
//     "partner-portal",
//     "/v2/partner-portal/branded-kinvault-invitation",
//   )
//     .method("post")
//     .body({
//       firstName: message.firstName,
//       lastName: message.lastName,
//       email: message.email,
//       externalRef: message.externalRef,
//       referralCode: message.referralCode,
//       adviser: message.adviser,
//       sendInvitationEmail: message.sendInvitationEmail,
//       brandedKinvault: message.brandedKinvault,
//     })
//     .result();
// };

export const CreateInviteHandler = DefineWriteAction({
  interface: "partner-portal",
  resource: "/v2/partner-portal/branded-kinvault-invitation",
  method: "post",
  parse: (message: {
    firstName: string;
    lastName: string;
    email: string;
    externalRef: null | string;
    referralCode: AccountReferralCode;
    adviser: IntroducerContact | null;
    sendInvitationEmail: null | boolean;
    brandedKinvault: BrandedKinvault;
  }) => {
    if (null === message.sendInvitationEmail) {
      throw new Error("sendInvitationEmail is required");
    }

    if (null === message.brandedKinvault) {
      throw new Error("brandedKinvault is required");
    }

    return {
      body: {
        firstName: message.firstName,
        lastName: message.lastName,
        email: message.email,
        externalRef: message.externalRef,
        referralCode: message.referralCode.id,
        adviser: message.adviser?.id || null,
        sendInvitationEmail: message.sendInvitationEmail,
        brandedKinvault: message.brandedKinvault?.id,
      },
    };
  },
});
