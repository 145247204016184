<template>
  <div>
    <Card v-if="title || description" :title="title" :is-plain="isPlain">
      <p v-if="description">
        {{ description }}
      </p>
    </Card>
    <div class="columns">
      <div v-if="items.isNotEmpty()" class="column is-narrow">
        <Tabs
          v-if="items.length > 0"
          class="sidebar-page__tabs"
          :tab="items.findIndex((item) => item.route === $route.name)"
          :config="items"
          is-vertical
          is-fullwidth
        />
      </div>
      <div class="column">
        <slot v-if="Object.keys($slots).isNotEmpty()" />
        <Card v-else>
          <RouterChildView v-bind="$attrs" />
        </Card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { TabItem } from "@/config/types";
import Tabs from "@kinherit/framework/component.display/tabs";
import Card from "@kinherit/framework/component.layout/card";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent, type PropType } from "vue";
export default defineComponent({
  name: "HelpSidebarPage",
  components: { Tabs, Card, RouterChildView },
  props: {
    items: {
      type: Array as PropType<Array<TabItem>>,
      default: () => [],
    },
    item: {
      type: Object as PropType<TabItem | null>,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    isPlain: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: null,
    },
  },
  emits: {
    "update:item": null as never as (_: TabItem | null) => boolean,
  },
  computed: {
    computedItem: {
      get(): Array<TabItem> {
        console.log(this.$route.name, this.items);
        return this.items.filter(
          (item) => this.$route.name === this.$router.resolve(item.route!).name,
        );
      },
      set(value: Array<TabItem>) {
        const last = value.last() ?? null;

        if (last) {
          this.$router.push(last.route!);
        }

        this.$emit("update:item", last);
      },
    },
  },
});
</script>
