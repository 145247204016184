import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { KinvaultAccessRequest } from "@kinherit/sdk";

export const RecorodKinvaultAccessRequest = DefineReadAction({
  interface: "partner-portal",
  resource: "/v2/partner-portal/access-request/{kinvaultAccessRequest}",
  rootModel: KinvaultAccessRequest,
  method: "get",
  parse: (message: KinvaultAccessRequest) => ({
    params: {
      kinvaultAccessRequest: message,
    },
  }),
});
