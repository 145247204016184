<template>
  <div class="help-faq-kinvault-access">
    <Card title="Overview">
      <FaqAccordian question="How can I access a clients Kinvault?">
        This functionality is only available to FCA registered partners where a
        direct client relationship exists. When enabled, you will be able to
        click "Request Access" on the client's profile page. The client will
        receive an email notification and will need to approve your request
        within 30 days.
      </FaqAccordian>
      <FaqAccordian question="Why can't I see the Request Access Button?">
        You may not have permission or the feature may not have been enabled for
        your organisation. Please contact sales@kinherit.co.uk for more
        information. Additionally, clients which have not yet got to a stage of
        "complete" in the onboarding process will not have the Request Access
        button available.
      </FaqAccordian>
      <FaqAccordian
        question="I've sent the request, but the client declined it by accident"
      >
        If your request gets declined, you will have the ability to resend the
        request. The client will receive a new email notification and will need
        to approve your request again.
      </FaqAccordian>
    </Card>
  </div>
</template>

<script lang="ts">
import { AuthService } from "@/service/auth.service";
import Card from "@kinherit/framework/component.layout/card";
import { defineComponent } from "vue";
import { HelpFaqKinvaultAccessRoute } from ".";
import FaqAccordian from "../component/FaqAccordian.vue";
export default defineComponent({
  name: HelpFaqKinvaultAccessRoute,
  components: { Card, FaqAccordian },
  mixins: [
    AuthService.mixin({
      module: "partner-portal",
    }),
  ],
});
</script>
