import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

export const AuthLoginRoute = "AuthLogin";

export const AuthRequestMfRoute = "AuthRequestMf";
const AuthRequestURI = "/multi-factor/:username";
export type AuthRequestMfParams = RouteParamObject<typeof AuthRequestURI>;

export const AuthForgotRoute = "AuthForgotRoute";
export const AuthMfAuthMethodsRoute = "AuthMfAuthMethods";
export const AuthPasswordResetRoute = "AuthPasswordReset";
export const AuthCreateMfRoute = "AuthCreateMf";

export const Routes: Array<RouteInterface> = [
  {
    name: AuthLoginRoute,
    path: "/",
    component: () => import("./Login.vue"),
  },
  {
    name: AuthRequestMfRoute,
    path: AuthRequestURI,
    component: () => import("./RequestMf.vue"),
  },
  {
    name: AuthForgotRoute,
    path: "/forgot",
    component: () => import("./Forgot.vue"),
  },
  {
    name: AuthPasswordResetRoute,
    path: "/reset-password/:token",
    component: () => import("./ResetPassword.vue"),
  },
  {
    name: AuthCreateMfRoute,
    path: "/multi-factor/create",
    component: () => import("./CreateMf.vue"),
  },
];
