<template>
  <PageLayout
    title="Authentication"
    strap="Add multifactor authentication to keep your account secure"
  >
    <div v-if="!loading">
      <div v-if="rows.length === 0">
        <p class="is-size-4">
          You have not setup multifactor authentication yet.
        </p>
        <p>Let's get started:</p>
        <div class="buttons">
          <Button
            text="Add Authentication Method"
            class="create-button"
            icon-left="Plus"
            aria-label="Create MFA Method"
            color="is-positive"
            @click="create"
          />
        </div>
      </div>
      <div v-else>
        <p class="is-size-4">
          Multifactor authentication is configured for this account.
        </p>
        <Table
          is-scrollable
          :columns="columns"
          :rows="rows"
          is-fullwidth
          is-narrow
          is-hoverable
        >
          <template #actions="{ row }: { row: MfAuthMethod }">
            <Button
              class="delete-button"
              icon-left="Trash"
              aria-label="Delete This Method"
              size="is-small"
              color="is-plain"
              @click="handleDelete(row)"
            />
            <!--Button
              v-if="row.setupCompleteAt === null"
              @click="handleResend(row)"
              class="resend-button"
              icon-left="Reset"
              ariaLabel="Reset MFA Code"
              size="is-small"
              color="is-plain"
            /-->
          </template>
          <template #alias="{ row }: { row: MfAuthMethod }">
            <b v-if="row.type === 'totp' && !row.alias"> Authenticator </b>
            <b v-else-if="row.device && !row.alias">{{ row.device }}</b>
            <b v-else-if="row.alias">{{ row.alias }}</b>
            <span v-else class="has-text-grey-light">No Alias</span>
          </template>
        </Table>
        <Button
          text="Add New Method"
          class="create-button mt-4"
          icon-left="Plus"
          aria-label="Create a MFA Method"
          color="is-positive"
          @click="create"
        />
      </div>
    </div>
  </PageLayout>
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { AuthService } from "@/service/auth.service";
import PageLayout from "@/shared/component/PageLayout.vue";
import Table from "@kinherit/framework/component.display/table";
import Button from "@kinherit/framework/component.input/button";
import { IMfAuthMethod, MfAuthMethod } from "@kinherit/sdk";
import { defineComponent } from "vue";
import {
  SettingsCreateMfAuthMethodsRoute,
  SettingsMfAuthMethodsRoute,
} from ".";

export default defineComponent({
  name: SettingsMfAuthMethodsRoute,
  components: {
    Table,
    Button,
    PageLayout,
  },
  mixins: [
    AuthService.mixin({
      module: "logged-in",
    }),
  ],
  data: () => ({
    loading: true,
    columns: [
      {
        title: "Alias",
        sort: true,
        slot: "alias",
      },
      {
        title: "Type",
        sort: true,
        map: (v: MfAuthMethod) => {
          if (v.type === "email") {
            return "Email";
          }

          return v.type.toUpperCase();
        },
      },
      {
        title: "Actions",
        sort: false,
        slot: "actions",
        class: "is-narrow",
      },
    ],
    rows: Array<MfAuthMethod>(),
    sort: {
      by: "date" as keyof IMfAuthMethod,
      direction: "asc" as "desc" | "asc",
    },
  }),
  mounted(): void {
    this.refresh();
  },
  methods: {
    async create(): Promise<void> {
      window.Kernel.visitRoute({
        name: SettingsCreateMfAuthMethodsRoute,
      });
    },
    async refresh(): Promise<void> {
      this.loading = true;
      // const { mfAuthMethods } = await window.Kernel.ActionBus.execute(
      //   "core/mf-auth-methods/read",
      //   {},
      // );
      const { mfAuthMethods } =
        await window.Kernel.ActionBus.core.mfAuthMethods.read({});

      this.rows = mfAuthMethods;

      this.loading = false;
    },
    async handleDelete(mfAuthMethod: MfAuthMethod): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete Authentication Method",
          message: `Are you sure you want to remove ${
            mfAuthMethod.alias ?? `this authentication method`
          }?`,
        },
      });

      // await window.Kernel.ActionBus.execute("core/mf-auth-methods/delete", {
      //   mfAuthMethod: mfAuthMethod,
      // });
      await window.Kernel.ActionBus.core.mfAuthMethods.delete({
        mfAuthMethod: mfAuthMethod,
      });

      await this.refresh();
    },
    async handleResend(mfAuthMethod: MfAuthMethod): Promise<void> {
      // await window.Kernel.ActionBus.execute("core/mf-auth-methods/resend", {
      //   mfAuthMethod: mfAuthMethod,
      // });
      await window.Kernel.ActionBus.core.mfAuthMethods.resend({
        mfAuthMethod: mfAuthMethod,
      });
    },
    cancel(): void {
      this.$router.back();
    },
  },
});
</script>
