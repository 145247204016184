export const HelpIndexBreadCrumb = {
  text: "Help & FAQs",
  route: { name: "HelpFaqEstatePlanning" },
};

export const HelpFaqEstatePlanningBreadCrumb = {
  text: "Estate Planning",
  route: { name: "HelpFaqEstatePlanning" },
};

export const HelpFaqKinvaultBreadCrumb = {
  text: "Kinvault",
  route: { name: "HelpFaqKinvault" },
};

export const HelpFaqKinvaultAccessBreadCrumb = {
  text: "Kinvault Access",
  route: { name: "HelpFaqKinvaultAccess" },
};
