<template>
  <PageLayout v-if="loaded" :title="computedClientName">
    <p v-if="isStandaloneKinvault">
      This account was added as the result of a client accepting a Kinvault
      invitation.
    </p>
    <template v-if="!isStandaloneKinvault">
      <Wizard
        ref="wizard"
        v-model:step="wizardStep"
        :config="wizardConfig"
        :size="'is-small'"
        color="is-success"
      />
      <div class="columns">
        <div class="column">
          <p v-if="accountReferral && accountReferral.kintin">
            Assigned Estate Planner:
            {{
              accountReferral.kintin?.estatePlanners?.first()?.profile
                ?.fullName ?? "Unassigned"
            }}
          </p>
        </div>
      </div>
    </template>
    <div class="columns">
      <DataListWidget
        v-if="accountReferral"
        :details="clientDetails"
        class="column mt-3"
        is-plain
        no-card
      />
      <DataListWidget
        v-if="accountReferral"
        :details="referralDetails"
        class="column"
        is-plain
        no-card
      />
      <DataListWidget
        v-if="accountReferral"
        :details="accountDetails"
        class="column"
        is-plain
        no-card
      />
    </div>
    <Message v-if="accountStatus?.text === 'On Hold'" title="On Hold">
      <ul class="list">
        <li class="list-item">
          <strong
            >On Hold till {{ accountReferral.nextActionAt?.formatLong }}</strong
          >
          <span class="is-block"
            >This client is still interested in proceeding but has requested we
            followup at a later date</span
          >
        </li>
      </ul>
    </Message>

    <Message
      v-for="(order, i) in orders"
      :key="`order-${i}`"
      :title="`${order.createdAt.formatDate}`"
    >
      <ul class="list">
        <li
          v-for="(orderItem, j) in order.orderItems"
          :key="`order-${i}-item-${j}`"
          class="list-item"
        >
          <strong>{{ orderItem.text }}</strong>
        </li>
      </ul>
    </Message>

    <Message v-if="warnings.length" title="How you can help us">
      <ul class="list">
        <li v-for="(warning, i) in warnings" :key="i" class="list-item">
          <strong>{{ warning.title }}</strong>
          <span class="is-block">{{ warning.message }}</span>
          <strong v-if="warning.cta" class="is-block">{{ warning.cta }}</strong>
        </li>
      </ul>
    </Message>

    <div v-if="kintinAccess || canRequestKintinAccess || accessRequest">
      <hr />
      <h3 class="has-text-weight-normal">Kinvault Access</h3>
      <div class="columns is-multiline mt-4">
        <div class="column is-narrow">
          <img
            src="/icons/kinvault-icon.svg"
            alt="Kinvault Icon"
            class="kinvault-icon"
          />
        </div>
        <div class="column">
          <div class="kinvault-content">
            <p>
              FCA regulated entities with a direct client relationship can
              request access to a client's Kinvault. Access is limited to the
              Asset Register, and needs to be explicitly granted by the client.
              <strong v-if="kintinAccess">You have been granted access.</strong>
              <strong v-if="accessRequest?.status === 'pending'"
                >Your request (sent on
                {{ accessRequest?.createdAt.formatDate }}) is awaiting action
                from the client.</strong
              >
            </p>
            <div class="mt-3">
              <Button
                v-if="kintinAccess"
                is-rounded
                color="is-success"
                @click="viewKinvault"
              >
                Open Kinvault <Icon :icon="ThemeIconName.KinvaultAssets" />
              </Button>
              <div
                v-else-if="accessRequest?.status === 'pending'"
                class="badge is-warning is-medium is-rounded is-opaque"
              >
                <span> Pending </span>
              </div>
              <Button
                v-else-if="canRequestKintinAccess"
                is-rounded
                color="is-positive"
                @click="requestKintinAccess"
              >
                Request Kinvault Access
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </PageLayout>
</template>

<script lang="ts">
import { ParterPortalMixin } from "@/module/dashboard/mixin/partner-portal.mixin";
import { AuthService } from "@/service/auth.service";
import DataListWidget from "@/shared/component/DataListWidget.vue";
import PageLayout from "@/shared/component/PageLayout.vue";
import { DataListWidgetDetail } from "@/shared/component/data-list-widget";
import Message from "@kinherit/framework/component.display/message";
import { Wizard } from "@kinherit/framework/component.display/wizard";
import { WizardConfig } from "@kinherit/framework/component.display/wizard/types";
import Button from "@kinherit/framework/component.input/button";
import { Equal } from "@kinherit/orm/index";
import {
  AccountReferral,
  KintinAccess,
  KinvaultAccessRequest,
  Order,
} from "@kinherit/sdk";
import { defineComponent } from "vue";
import { RequestKinvaultAccessForm } from "../form/request-kinvault-access.form";

import { DateTime, Uuid } from "@kinherit/ts-common";

import { SnackBarService } from "@/service/snack-bar.service";
import Icon from "@kinherit/framework/component.display/icon";
import { ThemeIconName } from "@kinherit/framework/theme/prop/icon";
import {
  PartnerPortalAccountReferralDetailsParams,
  PartnerPortalAccountReferralDetailsRoute,
} from ".";

type WarningObject = {
  title: string;
  message: string;
  cta?: string;
};

export default defineComponent({
  name: PartnerPortalAccountReferralDetailsRoute,
  components: {
    DataListWidget,
    Message,
    Wizard,
    PageLayout,
    Button,
    Icon,
  },
  mixins: [
    AuthService.mixin({
      module: "partner-portal",
    }),
    ParterPortalMixin,
  ],
  data: () => ({
    loaded: false,
    kinvaultUrl: null as string | null,
    accessRequest: null as KinvaultAccessRequest | null,
    ThemeIconName,
  }),
  computed: {
    $params(): PartnerPortalAccountReferralDetailsParams {
      return this.$route.params as PartnerPortalAccountReferralDetailsParams;
    },
    accountReferral(): AccountReferral {
      return AccountReferral.$findOneOrThrow(this.$params.accountReferral);
    },
    orders(): Array<Order> {
      return this.accountReferral.kintin?.orders ?? [];
    },
    computedClientName(): string {
      return (
        this.accountReferral.kintin?.friendlyName ??
        this.accountReferral.lead?.friendlyName ??
        "Error"
      );
    },
    accountStatus(): { text: string } {
      return this.calculateAccountReferralStatus(this.accountReferral);
    },
    isStandaloneKinvault(): boolean {
      return this.accountReferral.kintin?.process?.value === "lite";
    },
    wizardConfig(): WizardConfig {
      return [
        {
          title: "Prospect", // a or lead
        },
        {
          title: "Consultation", // b-c d
          message: "& Recommendations",
        },
        {
          title: "Paid", // e/e2/f
          message: "Completing Documents",
        },
        {
          title: "Complete", // g1/2/3/h/z1/z2
          message: this.wizardCompleteStepMessage,
        },
      ];
    },
    wizardCompleteStepMessage() {
      return this.accountReferral.kintin?.stage?.value &&
        ["g3"].includes(this.accountReferral.kintin?.stage?.value)
        ? "Done pending OPG / LR"
        : "All done";
    },
    wizardStep(): number {
      if (this.accountReferral.kintin?.stage?.value) {
        if (
          ["b", "c", "d"].includes(this.accountReferral.kintin?.stage?.value)
        ) {
          return 1;
        }
        if (
          ["e", "e2", "f"].includes(this.accountReferral.kintin?.stage?.value)
        ) {
          return 2;
        }
        if (
          ["g1", "g2", "g3", "h", "z1", "z2"].includes(
            this.accountReferral.kintin?.stage?.value,
          )
        ) {
          return 3;
        }
      }
      return 0;
    },
    warnings(): Array<WarningObject> {
      const warnings: Array<WarningObject> = [];

      if (
        null === this.accountReferral.kintin &&
        this.accountReferral.lead?.phoneNumbers?.length === 0
      ) {
        warnings.push({
          title: "Missing Phone Number",
          message: "We are missing a phone number for this referral.",
          cta: "Please email legalassistants@kinherit.co.uk with a phone number for this referral.",
        });
      }

      if (
        null !== this.accountReferral.kintin &&
        !this.accountReferral.kintin?.isActive
      ) {
        warnings.push({
          title: "Account Inactive",
          message:
            "This account has been flagged as inactive. This is usually due to inactivity on behalf of the client, and where we have been unable to contact them.",
          cta: "Please contact the client to confirm their intentions.",
        });
      }

      if (
        null === this.accountReferral.kintin &&
        ["uc_no_tel", "uc_with_tel"].includes(
          this.accountReferral.lead?.status?.value ?? "",
        )
      ) {
        warnings.push({
          title: "Uncontactable",
          message:
            "We have tried to contact this client mulitple times, but have been unable to reach them.",
          cta: "Please contact the client to confirm their intentions.",
        });
      }

      return warnings;
    },
    clientDetails(): Array<DataListWidgetDetail> {
      if (!this.accountReferral) {
        return [];
      }
      return [
        {
          label: "Name",
          value:
            this.accountReferral.kintin?.friendlyName ??
            this.accountReferral.lead?.friendlyName ??
            "Error",
        },
        {
          label: "Email",
          value:
            this.accountReferral.lead?.emailAddresses &&
            this.accountReferral.lead?.emailAddresses?.length > 0
              ? this.accountReferral.lead?.emailAddresses
                  .map((e) => e.email)
                  .join(", ")
              : "None Supplied",
        },
        {
          label: "Phone",
          value:
            this.accountReferral.lead?.phoneNumbers &&
            this.accountReferral.lead?.phoneNumbers?.length > 0
              ? this.accountReferral.lead?.phoneNumbers
                  .map((t) => t.tel)
                  .join(", ")
              : "None Supplied",
        },
      ];
    },
    referralDetails(): Array<DataListWidgetDetail> {
      if (!this.accountReferral) {
        return [];
      }
      return [
        {
          label: "Referred On",
          value: this.accountReferral.createdAt.formatDate,
        },
        {
          label: "Referred By",
          value:
            this.accountReferral.contact?.profile?.fullName ?? "Unassigned",
        },
        {
          label: "Referral Code",
          value: this.accountReferral.referralCode?.code ?? "None",
        },
      ];
    },
    accountDetails(): Array<DataListWidgetDetail> {
      if (!this.accountReferral) {
        return [];
      }
      return [
        {
          label: "Status",
          value: this.accountStatus.text,
        },
        {
          label: "Account Type",
          value:
            this.accountReferral.kintin?.type?.value === "joint"
              ? "Joint"
              : this.accountReferral.kintin?.type?.value === "single"
                ? "Individual"
                : "Prospect",
        },
        {
          label: "Account Number",
          value: this.accountReferral.kintin?.ref ?? "N/A",
        },
      ];
    },
    kintinAccess(): KintinAccess | null {
      if (!this.$auth.portalPermissions.allowKinvaultAccess) {
        return null;
      }

      if (!this.accountReferral.kintin) {
        return null;
      }

      if (!this.$auth.activeIntroducerContact) {
        return null;
      }

      // Disallow except if stage is > f and not deceased
      if (
        !["f", "g1", "g2", "g3", "h", "z1"].includes(
          this.accountReferral.kintin?.stage?.value ?? "",
        )
      ) {
        return null;
      }

      return (
        this.accountReferral.kintin.assignedUsers.findBy(
          "introducerContact.id",
          this.$auth.activeIntroducerContact.id,
        ) ?? null
      );
    },
    canRequestKintinAccess(): boolean {
      if (this.kintinAccess) {
        return false;
      }

      if (!this.$auth.portalPermissions.allowKinvaultAccess) {
        return false;
      }

      if (!this.$auth.portalPermissions.allowKinvaultAccessRequest) {
        return false;
      }

      // Disallow except if stage is > f and not deceased
      if (
        !["e", "e2", "f", "g1", "g2", "g3", "h"].includes(
          this.accountReferral.kintin?.stage?.value ?? "",
        )
      ) {
        return false;
      }

      return true;
    },
  },
  async beforeMount(): Promise<void> {
    await window.Kernel.ActionBus.clients.record({
      message: {
        accountReferral: this.$params.accountReferral,
      },
    });

    const permissions = this.$auth.portalPermissions;

    if (permissions.viewOrderProducts) {
      await window.Kernel.ActionBus.clients.order.read({
        message: {
          accountReferral: this.$params.accountReferral,
        },
        sort: {
          by: "createdAt",
          direction: "desc",
        },
      });
    }

    this.kinvaultUrl = await window.Kernel.ActionBus.core.KinvaultUrl();

    this.loaded = true;

    if (this.$auth.portalPermissions.allowKinvaultAccess) {
      const response =
        await window.Kernel.ActionBus.clients.kinvaultAccessRequest.read({
          query: {
            contact: Equal(this.$auth.activeIntroducerContact?.id),
            company: Equal(this.$auth.activeIntroducerCompany?.id),
            kintin: Equal(this.accountReferral.kintin?.id),
          },
        });

      this.accessRequest = response.kinvaultAccessRequest.first() ?? null;
    }
  },
  methods: {
    async viewKinvault(): Promise<void> {
      const kintin = this.accountReferral.kintin;

      if (!kintin) {
        return;
      }

      const token =
        await window.Kernel.ActionBus.clients.CreateKinvaultAuthToken({
          kintin,
        });

      const url = `${this.kinvaultUrl}/token/${token}/${kintin.id}`;

      window.open(url, "_blank");
    },
    async requestKintinAccess(): Promise<void> {
      const contact = this.$auth.activeIntroducerContact;
      const company = this.$auth.activeIntroducerCompany;
      const kintin = this.accountReferral.kintin;

      if (!contact || !company || !kintin) {
        return;
      }

      await RequestKinvaultAccessForm(!!this.accessRequest).dialog({
        dialog: {
          title: "Request Kinvault Access",
          type: "stacked",
        },
        button: {
          ok: {
            text: this.accessRequest
              ? "Request Access Again"
              : "Request Access",
          },
          cancel: {
            text: "Cancel",
          },
        },
      });

      // If the user confirms, we create the request
      this.$data.accessRequest = new KinvaultAccessRequest({
        id: Uuid.generate(),
        contact: contact.id,
        company: company.id,
        kintin: kintin.id,
        status: "pending",
        createdAt: new DateTime().formatMachine,
      });

      this.$data.accessRequest.$persist();

      await window.Kernel.ActionBus.clients.kinvaultAccessRequest.create(
        this.$data.accessRequest,
      );

      // Display a success message
      SnackBarService.success.generic("Kinvault access request sent");
    },
  },
});
</script>

<cypress-wrapper lang="json">
{
  "name": "AccountReferralDetails",
  "imports": {
    "WizardWrapper": "@kinherit/framework/component.display/wizard/wizard.test"
  },
  "methods": {
    "back": {
      "type": "click",
      "selector": ".back-button"
    },
    "wizardStep": {
      "type": "to-one",
      "selector": ".wizard",
      "wrapper": "WizardWrapper"
    }
  }
}
</cypress-wrapper>

<style lang="scss">
.kinvault-icon {
  width: 100px;
  height: 100px;
}

.kinvault-content {
  display: flex;
  flex-direction: column;
  height: 100%;

  p {
    flex-grow: 1;
  }
}

@media screen and (max-width: 768px) {
  .columns.is-multiline {
    text-align: center;

    .column.is-narrow {
      flex: 0 0 100%;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
