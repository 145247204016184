import { Api } from "@kinherit/sdk/api";

export const KinvaultUrlHandler = async () => {
  const request = Api.resource(
    "core",
    "/v2/core/environment/kinvault-url",
  ).method("get");

  return await request.result();
};
