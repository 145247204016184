<template>
  <PageLayout
    class="partner-portal-invite-master-list"
    title="Your Invitations"
    strap="Manage your Kinvault invitations"
  >
    <MasterListPage
      v-if="$data.filters"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
      :filters="$data.filters"
      :columns="columns"
      :rows="rows"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      is-plain
      :sort-by-options="{
        createdAt: 'Created',
      }"
      :persist-state="true"
      @refresh="refresh"
    >
      <template #name="{ row }: { row: BrandedKinvaultInvitation }">
        <strong class="is-block">{{ row.profile?.fullName }}</strong>
        <small class="is-block">{{
          row.profile?.primaryEmailAddress?.email
        }}</small>
      </template>
      <template #status="{ row }: { row: BrandedKinvaultInvitation }">
        <Badge v-if="row.status === 'notsent'" is-rounded color="is-warning">
          Not Sent
        </Badge>
        <Badge v-else-if="row.status === 'sent'" is-rounded color="is-info">
          Sent
        </Badge>
        <Badge
          v-else-if="row.status === 'complete'"
          is-rounded
          color="is-success"
        >
          Completed
        </Badge>
        <Badge v-else-if="row.status === 'failed'" is-rounded color="is-danger">
          Failed
        </Badge>
        <Badge
          v-else-if="row.status === 'declined'"
          is-rounded
          color="is-danger"
        >
          Declined
        </Badge>
        <Badge
          v-else-if="row.status === 'cancelled'"
          is-rounded
          color="is-danger"
        >
          Cancelled
        </Badge>
      </template>
      <template #actions="{ row }: { row: BrandedKinvaultInvitation }">
        <Button
          v-if="['notsent', 'sent'].includes(row.status)"
          class="button copy-button"
          icon-left="TextLink"
          tooltip="Copy Invitation Link"
          color="is-plain"
          size="is-large"
          @click="copyInvitationLink(row)"
        />
        <Button
          v-if="['notsent'].includes(row.status)"
          class="button send-button"
          icon-left="Email"
          tooltip="Send Invitation Email"
          color="is-plain"
          size="is-large"
          @click="handleSend(row)"
        />
        <Button
          v-if="['notsent'].includes(row.status)"
          class="button mark-sent-button"
          tooltip="Mark as Sent"
          icon-left="EmailTick"
          color="is-plain"
          size="is-large"
          @click="handleMarkSent(row)"
        />
        <Button
          v-if="['sent', 'failed'].includes(row.status)"
          class="button resend-button"
          tooltip="Resend Invitation Email"
          icon-left="Reset"
          color="is-plain"
          size="is-large"
          @click="handleSend(row)"
        />
        <Button
          v-if="['sent', 'notsent', 'declined', 'failed'].includes(row.status)"
          class="edit-button button"
          icon-left="Horizontalellipsis"
          size="is-large"
          color="is-plain"
          :aria-label="`Additional ${row.profile?.fullName} invitations options`"
          @click="openContextMenu(row)"
        />
      </template>
    </MasterListPage>
  </PageLayout>
</template>

<script lang="ts">
import {
  ConfirmDialog,
  DangerDialog,
  WarningDialog,
} from "@/config/dialog.config";
import { ParterPortalMixin } from "@/module/dashboard/mixin/partner-portal.mixin";
import { ReadInvitesForm } from "@/module/invites/form/read-invites.form";
import { AuthService } from "@/service/auth.service";
import PageLayout from "@/shared/component/PageLayout.vue";
import Badge from "@kinherit/framework/component.display/badge";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { ThemeIconName } from "@kinherit/framework/theme/prop/icon";
import {
  BrandedKinvaultInvitation,
  IBrandedKinvaultInvitation,
} from "@kinherit/sdk";
import { defineComponent } from "vue";
import { PartnerPortalInviteMasterListRoute } from ".";

import { OpenContextMenu } from "@kinherit/framework/global/context-menu";
import { ClipBoardService } from "@kinherit/framework/service/clip-board-service";
export default defineComponent({
  name: PartnerPortalInviteMasterListRoute,
  components: { MasterListPage, PageLayout, Button, Badge },
  mixins: [
    AuthService.mixin({
      module: "partner-portal",
    }),
    ParterPortalMixin,
  ],
  data: () => ({
    ThemeIconName,
    filters: ReadInvitesForm(),
    columns: [
      {
        title: "Date Added",
        sort: true,
        map: (v) => v.createdAt.formatDate,
      },
      {
        title: "Name",
        sort: true,
        slot: "name",
      },
      {
        title: "Status",
        slot: "status",
      },
      {
        slot: "actions",
        class: "has-text-right",
      },
    ] as Array<TableColumn<BrandedKinvaultInvitation>>,
    rows: Array<BrandedKinvaultInvitation>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IBrandedKinvaultInvitation,
      direction: "desc" as "desc" | "asc",
    },
  }),
  methods: {
    async openContextMenu(
      invitation: BrandedKinvaultInvitation,
    ): Promise<void> {
      const items = [];

      if (invitation.status !== "declined") {
        items.push({
          label: "Decline",
          line1: "Mark invitation as declined",
          icon: "Ban",
          action: this.handleMarkDeclined,
        });
      }

      items.push({
        label: "Delete",
        line1: "Delete this invitation",
        icon: "Trash",
        action: this.handleDelete,
      });

      const result = await OpenContextMenu({
        items,
        trackingField: "label",
        titleField: "label",
        line1Field: "line1",
        iconLeftField: "icon",
      });

      await result.action(invitation);
    },
    async refresh(
      formData: ReturnType<typeof ReadInvitesForm>["localData"],
    ): Promise<void> {
      // const data = await window.Kernel.ActionBus.execute(
      //   "partner-portal/invites/read",
      //   {
      //     ...formData,
      //     sort: this.sort,
      //     pagination: this.pagination,
      //   },
      // );
      const data = await window.Kernel.ActionBus.invites.read({
        // ...formData,
        message: formData,
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.brandedKinvaultInvitation;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
    async handleDelete(brandedKinvaultInvitation: BrandedKinvaultInvitation) {
      await DangerDialog({
        dialog: {
          title: "Delete Invitation",
          message:
            "Are you sure you want to delete this invitation? If the invitation has been sent, the link will no longer work and may lead to confusion.",
        },

        button: {
          ok: {
            text: "Yes, Delete",
            color: "is-danger",
          },
          cancel: {
            text: "Cancel",
          },
        },
      });

      // await window.Kernel.ActionBus.execute("partner-portal/invites/delete", {
      //   brandedKinvaultInvitation,
      // });
      await window.Kernel.ActionBus.invites.delete({
        brandedKinvaultInvitation,
      });

      await this.refresh(this.filters.localData);
    },
    async handleSend(invitation: BrandedKinvaultInvitation) {
      const email = invitation.profile?.primaryEmailAddress?.email;
      await ConfirmDialog({
        dialog: {
          title: "Send Invitation",
          message: `Are you sure you want to send an invitation email to ${email}?`,
        },
        button: {
          ok: {
            text: "Yes, Send",
          },
        },
      });

      // await window.Kernel.ActionBus.execute(
      //   "partner-portal/invites/send-invite-email",
      //   {
      //     brandedKinvaultInvitation: invitation,
      //   },
      // );
      await window.Kernel.ActionBus.invites.sendInviteEmail({
        brandedKinvaultInvitation: invitation,
      });
      await this.refresh(this.filters.localData);
    },
    async copyInvitationLink(invitation: BrandedKinvaultInvitation) {
      const url = `https://${invitation.brandedKinvault.frontendUrl}/accept-invite/${invitation.signUpToken}`;

      ClipBoardService.copyTextToClipBoard(url, {
        wrapLinks: false,
        showSnackbar: true,
      });
    },
    async handleMarkSent(brandedKinvaultInvitation: BrandedKinvaultInvitation) {
      await WarningDialog({
        dialog: {
          title: "Mark as Sent",
          message:
            "Are you sure you want to mark this invitation as sent? This assumes you've sent the invitation link directly to the client.",
        },
      });
      this.setStatus(brandedKinvaultInvitation, "sent");
    },
    async handleMarkDeclined(
      brandedKinvaultInvitation: BrandedKinvaultInvitation,
    ) {
      await WarningDialog({
        dialog: {
          title: "Decline Invitation",
          message:
            "Are you sure you want to mark this invitation as declined? This is irreversible and the client will need to be re-invited.",
        },
        button: {
          ok: {
            text: "Yes, Decline",
          },
          cancel: {
            text: "Cancel",
          },
        },
      });
      this.setStatus(brandedKinvaultInvitation, "declined");
    },
    async setStatus(
      brandedKinvaultInvitation: BrandedKinvaultInvitation,
      status: BrandedKinvaultInvitation["status"],
    ) {
      // await window.Kernel.ActionBus.execute("partner-portal/invites/update", {
      //   brandedKinvaultInvitation,
      //   status,
      // });
      await window.Kernel.ActionBus.invites.update({
        brandedKinvaultInvitation,
        status,
      });

      await this.refresh(this.filters.localData);
    },
  },
});
</script>

<cypress-wrapper lang="json">
{
  "name": "InviteMasterListPageWrapper",
  "route": "PartnerPortalInviteMasterList",
  "selector": ".partner-portal-invite-master-list",
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "imports": {
    "ReadInvitesFormWrapper": "@/module/invites/form/read-invites.form.test"
  },
  "methods": {
    "entries": {
      "type": "to-many",
      "selector": "tbody",
      "wrapper": "InviteMasterListRow"
    },
    "filter": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadInvitesFormWrapper"
    }
  }
}
</cypress-wrapper>

<cypress-wrapper lang="json">
{
  "name": "InviteMasterListRow",
  "methods": {
    "copyInvitationLink": {
      "type": "click",
      "selector": ".copy-button"
    },
    "sentInvitationEmail": {
      "type": "click",
      "selector": ".send-button"
    },
    "markAsSent": {
      "type": "click",
      "selector": ".mark-sent-button"
    },
    "resendInvitationEmail": {
      "type": "click",
      "selector": ".resend-button"
    },
    "openContextMenu": {
      "type": "click",
      "selector": ".edit-button"
    }
  }
}
</cypress-wrapper>
