import {
  IntroducerContactField,
  ReferralCodeField,
} from "@/config/form.config";
import { AuthService } from "@/service/auth.service";
import { FormTitle } from "@kinherit/framework/component.display/title";
import { FormElement } from "@kinherit/framework/component.form/element";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormDateField } from "@kinherit/framework/component.input/date-field";
import { FormEmailField } from "@kinherit/framework/component.input/email-field";
import { FormFileField } from "@kinherit/framework/component.input/file-field";
import { FormPhoneNumberField } from "@kinherit/framework/component.input/phone-number-field";
import { FormRadioField } from "@kinherit/framework/component.input/radio-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout/layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal } from "@kinherit/orm";
import { AccountReferralCode, IntroducerContact } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common";
import { reactive } from "vue";

const validNameRegex = (name: string): any => [
  "regex",
  {
    message: `Please enter a valid ${name}`,
    regex: "[a-z|A-Z]",
  },
];

enum Slots {
  IntroText = "IntroText",
  PrimaryFirstName = "PrimaryFirstName",
  SecondaryFirstName = "SecondaryFirstName",
  PrimaryLastName = "PrimaryLastName",
  SecondaryLastName = "SecondaryLastName",
  PrimaryEmailAddress = "PrimaryEmailAddress",
  SecondaryEmailAddress = "SecondaryEmailAddress",
  PrimaryTel = "PrimaryTel",
  IsJoint = "isJoint",
  SecondaryTitle = "SecondaryTitle",
  SecondaryTel = "SecondaryTel",
  ReferralText = "ReferralText",
  ReferralContact = "referralContact",
  ReferralCode = "ReferralCode",
  ContactPreferenceText = "ContactPreferenceText",
  Message = "Message",
  ContactWindow = "ContactWindow",
  ContactDate = "ContactDate",
  ProductInterestText = "ProductInterestText",
  PossibleProducts = "PossibleProducts",
  IWishToAttachFiles = "IWishToAttachFiles",
  AttachFiles = "AttachFiles",
}

type AccountReferralFormData = {
  primary: {
    firstName: string;
    lastName: string;
    tel: string;
    email: string;
  };
  secondary: {
    firstName: string;
    lastName: string;
    tel: string;
    email: string;
  };
  isJoint: boolean;
  message: string;
  contactWindow: "asap" | "fromDate" | "noContact";
  contactDate: DateTime | null;
  possibleProducts: Array<string>;
  referralContact: IntroducerContact | null;
  referralCode: AccountReferralCode | null;
  files: Array<File>;
};

const AccountReferralForm = () => {
  const localData = reactive({
    iWishToAttachFiles: false,
  });

  return defineForm({
    name: "account-referral-form",
    data: (): AccountReferralFormData => ({
      primary: {
        firstName: "",
        lastName: "",
        tel: "",
        email: "",
      },
      secondary: {
        firstName: "",
        lastName: "",
        tel: "",
        email: "",
      },
      isJoint: false,
      message: "",
      contactWindow: "asap",
      contactDate: null as null | DateTime,
      possibleProducts: Array<string>(),
      referralContact: AuthService.activeIntroducerContact ?? null,
      referralCode: AuthService.activeDefaultReferralCode ?? null,
      files: [] as Array<File>,
    }),
    formAreas: (data) => [
      defineFormArea({
        name: "client-details",
        data,
        template: GridLayout([
          [Slots.IntroText],
          [Slots.PrimaryFirstName, Slots.PrimaryLastName],
          [Slots.PrimaryEmailAddress, Slots.PrimaryTel],
          [Slots.IsJoint],
          [Slots.SecondaryTitle],
          [Slots.SecondaryFirstName, Slots.SecondaryLastName],
          [Slots.SecondaryEmailAddress, Slots.SecondaryTel],
          [Slots.ReferralText],
          [Slots.ReferralContact],
          [Slots.ReferralCode],
          [Slots.ContactPreferenceText],
          [Slots.ContactWindow],
          [Slots.ContactDate],
          [Slots.ProductInterestText],
          [Slots.PossibleProducts],
          [Slots.Message],
          [Slots.IWishToAttachFiles],
          [Slots.AttachFiles],
        ]),
        components: () => ({
          [Slots.IntroText]: [
            FormTitle({
              props: {
                text: "Client Details",
                htag: "h4",
                isEmphasised: true,
                class: "mt-3",
              },
            }),
          ],
          [Slots.PrimaryFirstName]: [
            FormTextField({
              models: {
                value: "primary.firstName",
              },
              props: {
                label: "First name",
                validators: ["required", validNameRegex("first name")],
                reference: "firstName",
              },
            }),
          ],
          [Slots.PrimaryLastName]: [
            FormTextField({
              models: {
                value: "primary.lastName",
              },
              props: {
                label: "Last name",
                validators: ["required", validNameRegex("last name")],
                reference: "lastName",
              },
            }),
          ],
          [Slots.PrimaryEmailAddress]: [
            FormEmailField({
              models: {
                value: "primary.email",
              },
              props: {
                label: "Email address",
                validators: ["required"],
                reference: "email",
              },
            }),
          ],
          [Slots.PrimaryTel]: [
            FormPhoneNumberField({
              models: {
                value: "primary.tel",
              },
              props: {
                label: "Telephone number",
                validators: ["required"],
                reference: "tel",
              },
            }),
          ],
          [Slots.IsJoint]: [
            FormCheckboxField({
              reactive: true,
              props: {
                label: "This is a joint referral",
                reference: "isJoint",
              },
              models: {
                value: "isJoint",
              },
            }),
          ],
          [Slots.SecondaryTitle]: [
            FormTitle({
              props: {
                text: "Client Partner's Details",
                vIf: (data) => data.isJoint,
                htag: "h4",
                isEmphasised: true,
                class: "mt-3",
              },
            }),
          ],
          [Slots.SecondaryFirstName]: [
            FormTextField({
              models: {
                value: "secondary.firstName",
              },
              props: {
                vIf: (data) => data.isJoint,
                label: "First name",
                validators: ["required", validNameRegex("first name")],
                reference: "firstName",
              },
            }),
          ],
          [Slots.SecondaryLastName]: [
            FormTextField({
              models: {
                value: "secondary.lastName",
              },
              props: {
                vIf: (data) => data.isJoint,
                label: "Last name",
                validators: ["required", validNameRegex("last name")],
                reference: "lastName",
              },
            }),
          ],
          [Slots.SecondaryEmailAddress]: [
            FormEmailField({
              models: {
                value: "secondary.email",
              },
              props: {
                vIf: (data) => data.isJoint,
                label: "Email address",
                reference: "email",
              },
            }),
          ],
          [Slots.SecondaryTel]: [
            FormPhoneNumberField({
              models: {
                value: "secondary.tel",
              },
              props: {
                vIf: (data) => data.isJoint,
                label: "Telephone number",
                reference: "tel",
              },
            }),
          ],
          [Slots.ReferralText]: [
            FormTitle({
              props: {
                text: "Referral Details",
                htag: "h4",
                isEmphasised: true,
                class: "mt-3",
              },
            }),
          ],
          [Slots.ReferralCode]: [
            ReferralCodeField({
              props: {
                label: "Referral Code",
                message: "Please select your referral code",
                vModel: "referralCode",
                validators: ["required"],
                reference: "referralCode",
              },
              query: {
                company: {
                  id: Equal(AuthService.activeIntroducerCompany?.id),
                },
              },
            }),
          ],
          [Slots.ReferralContact]: [
            IntroducerContactField({
              props: {
                label: "Please select the person for whom this is a referral:",
                message:
                  "If the person you are looking for is not listed, please contact us.",
                validators: ["required"],
                vModel: "referralContact",
                reference: "referralContact",
              },
              query: {
                companies: {
                  id: Equal(AuthService.activeIntroducerCompany?.id),
                },
              },
            }),
          ],
          [Slots.Message]: [
            FormTextField({
              props: {
                label:
                  "Please provide some background information, such as their family situation, or any other detail which you think is relevant.",
                isTextArea: true,
                placeholder: "About this referral",
                reference: "message",
              },
              models: { value: "message" },
            }),
          ],
          [Slots.ContactPreferenceText]: [
            FormTitle({
              props: {
                text: "Contact Preferences",
                htag: "h4",
                isEmphasised: true,
                class: "mt-3",
              },
            }),
          ],
          [Slots.ContactWindow]: [
            FormRadioField({
              reactive: true,
              props: {
                label: "I would like them contacted:",
                options: {
                  asap: "As soon as possible",
                  fromDate: "From a specific date",
                  noContact: "Do not contact",
                },
                reference: "emailOptions",
                direction: "is-vertical",
              },
              models: {
                value: {
                  get: (data) => data.contactWindow,
                  set: (value, data) => (data.contactWindow = value),
                },
              },
            }),

            FormElement({
              props: {
                vIf: (data) => data.contactWindow === "noContact",
                html: `* Please provide further information below *`,
              },
            }),
          ],
          [Slots.ContactDate]: [
            FormDateField({
              props: {
                label: "Only contact this client from:",
                vIf: (data) => data.contactWindow === "fromDate",
                reference: "contactDate",
              },
              models: { value: "contactDate" },
            }),
          ],
          [Slots.ProductInterestText]: [
            FormTitle({
              props: {
                text: "Further Information",
                htag: "h4",
                isEmphasised: true,
                class: "mt-3",
              },
            }),
            FormElement({
              props: {
                html: `<h3 class="title is-5">What services are they interested in?</h3>`,
              },
            }),
          ],
          [Slots.PossibleProducts]: [
            FormCheckboxField({
              props: {
                label: "Getting a New Will",
                reference: "productNewWill",
              },
              models: {
                value: {
                  get: (data) => data.possibleProducts.includes("New Will"),
                  set: (value, data) => {
                    if (value) {
                      data.possibleProducts.push("New Will");
                    } else {
                      data.possibleProducts =
                        data.possibleProducts.remove("New Will");
                    }
                  },
                },
              },
            }),
            FormCheckboxField({
              props: {
                label: "Lasting Power of Attorney",
                reference: "productLpas",
              },
              models: {
                // value: "productLpas",
                value: {
                  get: (data) => data.possibleProducts.includes("LPAs"),
                  set: (value, data) => {
                    if (value) {
                      data.possibleProducts.push("LPAs");
                    } else {
                      data.possibleProducts =
                        data.possibleProducts.remove("LPAs");
                    }
                  },
                },
              },
            }),
            FormCheckboxField({
              props: {
                label: "Trusts",
                reference: "productTrust",
              },
              models: {
                // value: "productTrust",
                value: {
                  get: (data) => data.possibleProducts.includes("Trusts"),
                  set: (value, data) => {
                    if (value) {
                      data.possibleProducts.push("Trusts");
                    } else {
                      data.possibleProducts =
                        data.possibleProducts.remove("Trusts");
                    }
                  },
                },
              },
            }),
            FormCheckboxField({
              props: {
                label: "Other",
                reference: "productOther",
              },
              models: {
                // value: "productOther",
                value: {
                  get: (data) => data.possibleProducts.includes("Other"),
                  set: (value, data) => {
                    if (value) {
                      data.possibleProducts.push("Other");
                    } else {
                      data.possibleProducts =
                        data.possibleProducts.remove("Other");
                    }
                  },
                },
              },
            }),
          ],
          [Slots.IWishToAttachFiles]: [
            FormCheckboxField({
              props: {
                label: "I wish to attach additional files",
                reference: "iWishToAttachFiles",
              },
              models: {
                value: {
                  get: () => localData.iWishToAttachFiles,
                  set: (value, _, controls) => {
                    localData.iWishToAttachFiles = value;
                    controls.rebuildForm();
                  },
                },
              },
            }),
          ],
          [Slots.AttachFiles]: [
            FormFileField({
              props: {
                vIf: () => localData.iWishToAttachFiles,
                label: "Attach files",
              },
              models: {
                value: "files",
              },
            }),
          ],
        }),
      }),
    ],
  });
};

export { AccountReferralForm };
