<template>
  <div class="partner-dashboard-section mb-5">
    <h2 class="is-display">
      {{ title }}
    </h2>
    <p v-if="strap">
      {{ strap }}
    </p>
    <hr class="is-dashed" />
    <Grid :desktop="'one-quarter'" :tablet="'half'" :mobile="'full'">
      <template
        v-for="(item, i) in items"
        :key="`partner-section-tile-${i}`"
        #[i]
      >
        <DashboardTile
          :title="item.title"
          :strap="item.strap"
          :icon="item.icon"
          :route="item.route"
          :class="item.class"
        />
      </template>
    </Grid>
  </div>
</template>

<script lang="ts">
import { TDashboardTile } from "@/shared/component/dashboard-tile";
import { Grid } from "@kinherit/framework/component.layout/grid";
import { defineComponent, PropType } from "vue";
import DashboardTile from "./DashboardTile.vue";

export default defineComponent({
  name: "DashboardSection",
  components: { DashboardTile, Grid },
  props: {
    title: {
      type: String,
      required: true,
    },
    strap: {
      type: String as PropType<string | null>,
      default: null,
    },
    items: {
      type: Array as PropType<Array<TDashboardTile>>,
      required: true,
    },
  },
});
</script>
